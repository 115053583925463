import "./style.css";
import * as dat from "lil-gui";
import * as THREE from "three";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
import { OBJLoader } from "three/examples/jsm/loaders/OBJLoader";
import { MTLLoader } from "three/examples/jsm/loaders/MTLLoader";

/**
 * Loaders
 */

const mtlLoader = new MTLLoader();
const cubeTextureLoader = new THREE.CubeTextureLoader();

/**
 * Textures
 */

const loadingManager = new THREE.LoadingManager();
loadingManager.onStart = () => {
  console.log("loadingManager: loading started");
};
loadingManager.onLoad = () => {
  console.log("loadingManager: loading finished");
};
loadingManager.onProgress = () => {
  console.log("loadingManager: loading progressing");
};
loadingManager.onError = () => {
  console.log("loadingManager: loading error");
};

const textureLoader = new THREE.TextureLoader(loadingManager);
const normalMetalTexture = textureLoader.load(
  "/textures/metal/Metal_006_normal.jpg"
);
normalMetalTexture.wrapS = THREE.MirroredRepeatWrapping;
normalMetalTexture.wrapT = THREE.MirroredRepeatWrapping;
normalMetalTexture.repeat.x = 5;
normalMetalTexture.repeat.y = 50;

const normalPlasticTexture = textureLoader.load(
  "/textures/plastic/Plastic_004_normal.jpg"
);
normalPlasticTexture.wrapS = THREE.MirroredRepeatWrapping;
normalPlasticTexture.wrapT = THREE.MirroredRepeatWrapping;
normalPlasticTexture.repeat.x = 5;
normalPlasticTexture.repeat.y = 5;

const ambientOcclusionMetalTexture = textureLoader.load(
  "/textures/metal/Metal_006_ambientOcclusion.jpg"
);
const ambientOcclusionPlasticTexture = textureLoader.load(
  "/textures/plastic/Plastic_004_ambientOcclusion.jpg"
);

/**
 * Base
 */

// Debug
const gui = new dat.GUI();
const debugObject = {
  roughness: 0.5,
  metalness: 0.5,
  color: "#330b00",
  backgroundColor: "#9e9e9e",
  envMap: false,
  envMapIntensity: 2.5,
  toneMappingExposure: 0.5,
  focalLength: 18.545
};

const guiColors = gui.addFolder("Colors");
const guiEnv = gui.addFolder("Environment");

// Canvas
const canvas = document.querySelector("canvas.webgl");

// Scene
const scene = new THREE.Scene();

/**
 * Materials
 */

const metalMaterial = new THREE.MeshStandardMaterial({
  side: THREE.DoubleSide,
  roughness: 0.2,
  metalness: 0.9,
  aoMap: ambientOcclusionMetalTexture,
  aoMapIntensity: 5,
  normalMap: normalMetalTexture,
  normalScale: new THREE.Vector2(0.5, 0.5)
});

const plasticMaterial = new THREE.MeshStandardMaterial({
  side: THREE.DoubleSide,
  roughness: 0.7,
  metalness: 0.2,
  color: new THREE.Color(
    0.5271148493788722,
    0.5271148493788722,
    0.5271148493788722
  ),
  aoMap: ambientOcclusionPlasticTexture,
  aoMapIntensity: 5,
  normalMap: normalPlasticTexture,
  normalScale: new THREE.Vector2(0.5, 0.5)
});

const darkPlasticMaterial = new THREE.MeshStandardMaterial({
  side: THREE.DoubleSide,
  roughness: 0.7,
  metalness: 0.2,
  color: new THREE.Color(
    0.051269300665622644,
    0.051269300665622644,
    0.051269300665622644
  ),
  aoMap: ambientOcclusionPlasticTexture,
  aoMapIntensity: 5,
  normalMap: normalPlasticTexture,
  normalScale: new THREE.Vector2(0.5, 0.5)
});

const plasticScreenMaterial = new THREE.MeshStandardMaterial({
  side: THREE.DoubleSide,
  roughness: 0.8,
  metalness: 0.1,
  color: new THREE.Color(
    0.3049869793680621,
    0.3419146157062739,
    0.37626234996729047
  ),
  transparent: true,
  opacity: 0.2,
  depthWrite: false,
  aoMap: ambientOcclusionPlasticTexture,
  aoMapIntensity: 5,
  normalMap: normalPlasticTexture,
  normalScale: new THREE.Vector2(0.5, 0.5)
});

/**
 * Update all materials
 */
const updateAllMaterials = () => {
  if (debugObject.envMap) {
    scene.background = environmentMap;
    scene.environment = environmentMap;
  } else {
    scene.background = new THREE.Color(debugObject.backgroundColor);
    scene.environment = null;
  }

  scene.traverse(child => {
    if (
      child instanceof THREE.Mesh &&
      child.material instanceof THREE.MeshStandardMaterial
    ) {
      if (debugObject.envMap) {
        child.material.envMap = environmentMap;
        child.material.envMapIntensity = debugObject.envMapIntensity;
      } else {
        child.material.envMap = null;
        child.material.envMapIntensity = null;
      }
      child.material.needsUpdate = true;
    }
  });
};

/**
 * Environment map
 */

const environmentMap = cubeTextureLoader.load([
  "/textures/environmentMaps/0/px.png",
  "/textures/environmentMaps/0/nx.png",
  "/textures/environmentMaps/0/py.png",
  "/textures/environmentMaps/0/ny.png",
  "/textures/environmentMaps/0/pz.png",
  "/textures/environmentMaps/0/nz.png"
]);

environmentMap.encoding = THREE.sRGBEncoding;

/**
 * Models
 */

// Standard
// Acciaio
mtlLoader.load(
  "/models/standard/acciaio/pila205_acciaio.mtl",
  materials => {
    materials.preload();
    const loader = new OBJLoader();
    loader.setMaterials(materials);
    loader.load(
      "/models/standard/acciaio/pila205_acciaio.obj",
      object => {
        object.scale.set(0.25, 0.25, 0.25);
        object.children.forEach(element => {
          element.castShadow = true;
          element.receiveShadow = true;
          if (element.material instanceof Array === false) {
            element.geometry.setAttribute(
              "uv2",
              new THREE.BufferAttribute(element.geometry.attributes.uv.array, 2)
            );
            const color = element.material.color.clone();
            element.material = metalMaterial.clone();
            element.material.color = color;
          }
          element.material.needsUpdate = true;
        });
        scene.add(object);
      },
      xhr => {
        console.log((xhr.loaded / xhr.total) * 100 + "% loaded");
      },
      error => {
        console.log("An error happened");
        console.error(error);
      }
    );
  },
  xhr => {
    console.log((xhr.loaded / xhr.total) * 100 + "% loaded");
  },
  error => {
    console.log("An error happened");
    console.error(error);
  }
);

// Standard
// Plastica
// Dark Plastic
mtlLoader.load(
  "/models/standard/plastica/dark_plastic/pila205_dark_plastic.mtl",
  materials => {
    materials.preload();
    const loader = new OBJLoader();
    loader.setMaterials(materials);
    loader.load(
      "/models/standard/plastica/dark_plastic/pila205_dark_plastic.obj",
      object => {
        object.scale.set(0.25, 0.25, 0.25);
        object.children.forEach(element => {
          element.castShadow = true;
          element.receiveShadow = true;
          element.geometry.setAttribute(
            "uv2",
            new THREE.BufferAttribute(element.geometry.attributes.uv.array, 2)
          );
          element.material = darkPlasticMaterial;
          element.material.needsUpdate = true;
        });
        scene.add(object);
      },
      xhr => {
        console.log((xhr.loaded / xhr.total) * 100 + "% loaded");
      },
      error => {
        console.log("An error happened");
        console.error(error);
      }
    );
  },
  xhr => {
    console.log((xhr.loaded / xhr.total) * 100 + "% loaded");
  },
  error => {
    console.log("An error happened");
    console.error(error);
  }
);

// Standard
// Plastica
// Screen
mtlLoader.load(
  "/models/standard/plastica/screen/pila205_screen.mtl",
  materials => {
    materials.preload();
    const loader = new OBJLoader();
    loader.setMaterials(materials);
    loader.load(
      "/models/standard/plastica/screen/pila205_screen.obj",
      object => {
        object.scale.set(0.25, 0.25, 0.25);
        object.children.forEach(element => {
          element.castShadow = true;
          element.receiveShadow = true;
          element.geometry.setAttribute(
            "uv2",
            new THREE.BufferAttribute(element.geometry.attributes.uv.array, 2)
          );
          element.material = plasticScreenMaterial;
          element.material.needsUpdate = true;
        });
        scene.add(object);
      },
      xhr => {
        console.log((xhr.loaded / xhr.total) * 100 + "% loaded");
      },
      error => {
        console.log("An error happened");
        console.error(error);
      }
    );
  },
  xhr => {
    console.log((xhr.loaded / xhr.total) * 100 + "% loaded");
  },
  error => {
    console.log("An error happened");
    console.error(error);
  }
);

// Standard
// Plastica
// Led Strip
mtlLoader.load(
  "/models/standard/plastica/led_strip/pila205_led_strip.mtl",
  materials => {
    materials.preload();
    const loader = new OBJLoader();
    loader.setMaterials(materials);
    loader.load(
      "/models/standard/plastica/led_strip/pila205_led_strip.obj",
      object => {
        object.scale.set(0.25, 0.25, 0.25);
        object.children.forEach(element => {
          element.castShadow = true;
          element.receiveShadow = true;
          element.geometry.setAttribute(
            "uv2",
            new THREE.BufferAttribute(element.geometry.attributes.uv.array, 2)
          );
          element.material = plasticMaterial;
          element.material.needsUpdate = true;
        });
        scene.add(object);
      },
      xhr => {
        console.log((xhr.loaded / xhr.total) * 100 + "% loaded");
      },
      error => {
        console.log("An error happened");
        console.error(error);
      }
    );
  },
  xhr => {
    console.log((xhr.loaded / xhr.total) * 100 + "% loaded");
  },
  error => {
    console.log("An error happened");
    console.error(error);
  }
);

// Custom
// Carpentry
mtlLoader.load(
  "/models/custom/carpentry/pila205_custom_0.mtl",
  materials => {
    materials.preload();
    const loader = new OBJLoader();
    loader.setMaterials(materials);
    loader.load(
      "/models/custom/carpentry/pila205_custom_0.obj",
      object => {
        object.name = "custom";
        object.scale.set(0.25, 0.25, 0.25);
        object.children.forEach(element => {
          element.castShadow = true;
          element.receiveShadow = true;
          if (element.material instanceof Array === false) {
            element.geometry.setAttribute(
              "uv2",
              new THREE.BufferAttribute(element.geometry.attributes.uv.array, 2)
            );
            const color = element.material.color.clone();
            element.material = metalMaterial.clone();
            element.material.color = color;
          }
          element.material.needsUpdate = true;
        });
        scene.add(object);
      },
      xhr => {
        console.log((xhr.loaded / xhr.total) * 100 + "% loaded");
      },
      error => {
        console.log("An error happened");
        console.error(error);
      }
    );
  },
  xhr => {
    console.log((xhr.loaded / xhr.total) * 100 + "% loaded");
  },
  error => {
    console.log("An error happened");
    console.error(error);
  }
);

/**
 * Camera
 */

const sizes = {
  width: window.innerWidth,
  height: window.innerHeight
};

// Base camera
let camera = new THREE.PerspectiveCamera(
  75,
  sizes.width / sizes.height,
  0.1,
  1000
);
camera.position.set(0, 100, 550);
scene.add(camera);

/**
 * Lights
 */

const directionalLight = new THREE.DirectionalLight("#f5f5f5", 10);
directionalLight.castShadow = true;
directionalLight.shadow.camera.near = 1;
directionalLight.shadow.camera.far = 1000;
directionalLight.shadow.mapSize.set(1024, 1024);
directionalLight.shadow.radius = 100;
directionalLight.shadow.normalBias = 0.05;
directionalLight.position.set(0, 0, 0);
scene.add(directionalLight);

directionalLight.target = new THREE.Object3D();
directionalLight.target.position.set(0, 0, 0);
scene.add(directionalLight.target);

const ambientLight = new THREE.AmbientLight("#f5f5f5", 0.5);
scene.add(ambientLight);

const hemiLight = new THREE.HemisphereLight(0xffffff, 0xffffff, 10);
hemiLight.position.set(0, 100, 0);
scene.add(hemiLight);

/**
 * Sizes
 */

window.addEventListener("resize", () => {
  // Update sizes
  sizes.width = window.innerWidth;
  sizes.height = window.innerHeight;

  // Update camera
  camera.aspect = sizes.width / sizes.height;
  camera.updateProjectionMatrix();

  // Update renderer
  renderer.setSize(sizes.width, sizes.height);
  renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));
});

// Controls
const controls = new OrbitControls(camera, canvas);
// controls.target.copy(directionalLight.target.position);
controls.enableDamping = true;

/**
 * Renderer
 */

const renderer = new THREE.WebGLRenderer({
  canvas: canvas,
  antialias: true,
  powerPreference: "high-performance",
  preserveDrawingBuffer: true
});
renderer.physicallyCorrectLights = true;
renderer.outputEncoding = THREE.sRGBEncoding;
renderer.toneMapping = THREE.ACESFilmicToneMapping;
renderer.toneMappingExposure = debugObject.toneMappingExposure;
renderer.shadowMap.enabled = true;
renderer.shadowMap.type = THREE.PCFSoftShadowMap;
renderer.setSize(sizes.width, sizes.height);
renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));
renderer.setClearColor(debugObject.backgroundColor);

/**
 * Animate
 */

const tick = () => {
  // Update controls
  controls.update();

  directionalLight.position.set(
    camera.position.x,
    camera.position.y,
    camera.position.z
  );

  // Render
  renderer.render(scene, camera);
  // Call tick again on the next frame
  window.requestAnimationFrame(tick);
};

tick();

/**
 * Debug UI
 */

// Colors

guiColors.addColor(debugObject, "color").onChange(function () {
  const custom = scene.getObjectByName("custom");
  custom.children.forEach(element => {
    element.castShadow = true;
    element.material.color = new THREE.Color(debugObject.color);
    element.material.needsUpdate = true;
  });
});
// TODO ASK FOR CUSTOM FIXED COLORS
// gui.add( obj, 'colors', { Slow: 0.1, Normal: 1, Fast: 5 } )
guiColors.addColor(debugObject, "backgroundColor").onChange(function () {
  renderer.setClearColor(debugObject.backgroundColor);
  scene.background = new THREE.Color(debugObject.backgroundColor);
  renderer.render(scene, camera);
});

// Environment

guiEnv
  .add(renderer, "toneMapping", {
    No: THREE.NoToneMapping,
    Linear: THREE.LinearToneMapping,
    Reinhard: THREE.ReinhardToneMapping,
    Cineon: THREE.CineonToneMapping,
    ACESFilmic: THREE.ACESFilmicToneMapping
  })
  .onFinishChange(() => {
    renderer.toneMapping = Number(renderer.toneMapping);
    updateAllMaterials();
  });
guiEnv.add(renderer, "toneMappingExposure").min(0).max(1).step(0.01);

guiEnv.add(debugObject, "envMap").onChange(updateAllMaterials);
guiEnv
  .add(debugObject, "envMapIntensity")
  .min(0)
  .max(10)
  .step(0.001)
  .onChange(updateAllMaterials);
